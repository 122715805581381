import React from 'react'
import './LandingScrn.css'

function AboutmeScrn() {
    return (
      <div className='landing-container'>
          <div className="landing-line">
              <p className='line-numbers'>1</p>
              <p className='line-text'>/*A little about me*/</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>2</p>
              <p className='line-text'></p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>3</p>
              <p className='line-text'> 1) I am an international student who came to America in 2018 to attend Drexel University. During my time here, I completed three co-ops that provided me with valuable hands-on experience and diverse professional skills.</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>4</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>5</p>
              <p className='line-text'> 2) I am passionate about good design, believing that "Good design is as little design as possible." My love for data-driven improvements, especially in frontend enhancements, has driven me to create intuitive and effective user interfaces throughout my career.</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>6</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>7</p>
              <p className='line-text'>3) I have a strong inclination towards creating products for social good. Projects like Hygia, an app supporting opioid addiction recovery, and Hummingbird, a drone-based public safety tool, reflect my commitment to making a positive impact through technology.</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>8</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>9</p>
              <p className='line-text'> 4) My professional journey includes roles in web development, UI/UX design, and customer success. These experiences have honed my skills in creating user-centric solutions and collaborating with cross-functional teams to achieve project goals.</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>10</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>11</p>
              <p className='line-text'> 5) I am a lifelong learner, always seeking to improve my skills and stay updated with the latest trends in technology and design. This mindset allows me to adapt to new challenges and consistently deliver high-quality work.</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>12</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>13</p>
              <p className='line-text'> Languages = JavaScript(ES6)/ Typescript, HTML5, CSS, Python, Java, C, C++</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>14</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>15</p>
              <p className='line-text'> Frameworks = React, Node. D3.js, jQuery, Bootstrap, Vue, Jest, Angular, PostgreSQL</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>16</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>17</p>
              <p className='line-text'> Dev Tools = Git, Docker, Firebase, AWS, WordPress, Pendo, Google Analytics, Facebook Ads Manager</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>18</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>19</p>
              <p className='line-text'> Design Tools = Figma, AdobeXD, Sketch, Illustrator, Photoshop, After Effects</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>20</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>21</p>
              <p className='line-text'> Managerial Tools = Figma, AdobeXD, Sketch, Illustrator, Photoshop, After Effects</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>22</p>
              <p className='line-text'> </p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>23</p>
              <p className='line-text'> Soft Skills = Creativity, Organization, Customer service, Communication, Design knowledge
</p>
          </div>
          <div className="landing-line">
              <p className='line-numbers'>24</p>
              <p className='line-text'> */</p>
          </div>
      </div>
    )
  }

export default AboutmeScrn
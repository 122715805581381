import React from 'react'
import './LandingScrn.css'


function LandingScrn() {
  return (
    <div className='landing-container'>
        <div className="landing-line">
            <p className='line-numbers'>1</p>
            <p className='line-text'> /* </p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>2</p>
            <p className='line-text'> Hi! My name is Abu Zaid Khan and welcome to my website.</p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>3</p>
            <p className='line-text'> </p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>4</p>
            <p className='line-text'> I graduated from Drexel University in the spring of 2024.</p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>5</p>
            <p className='line-text'> </p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>6</p>
            <p className='line-text'> I majored in Interdisciplinary Studies with minors in Computer Science and Human-Computer Interaction.</p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>7</p>
            <p className='line-text'> </p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>8</p>
            <p className='line-text'> I invite you to look through my website and get to know me better.</p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>9</p>
            <p className='line-text'> You can use the left bar to look through my Work Experience, Projects, Classes I have taken, and my resume.</p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>10</p>
            <p className='line-text'> You can also use the ChatGPT powered search bar at the top to ask more specific details.</p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>11</p>
            <p className='line-text'> </p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>12</p>
            <p className='line-text'> Thank you for taking the time to view my work. I am currently persuing a role in frontend devlopement, UI/UX, design, and software engineering roles. </p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>13</p>
            <p className='line-text'> </p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>14</p>
            <p className='line-text'> Please feel free to contact me to discuss any opportunities. </p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>15</p>
            <p className='line-text'> Email: ak3776@drexel.edu, abuzaidkhan.drexel.24@gmail.com</p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>16</p>
            <p className='line-text'> LinkedIn: https://www.linkedin.com/in/abuzaidkhan/</p>
        </div>
        <div className="landing-line">
            <p className='line-numbers'>17</p>
            <p className='line-text'>*/</p>
        </div>
        
        <div className="landing-line">
            <p className='line-numbers'>18</p>
            <p className='line-text'></p>
        </div>
        
        <div className="landing-line">
            <p className='line-numbers'>19</p>
            <p className='line-text'>Last Updated 7/10/2024</p>
        </div>
    </div>
  )
}

export default LandingScrn